import React from 'react';

const Instagram = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="30.872"
    height="30.872"
    viewBox="0 0 30.872 30.872"
  >
    <path
      d="M15.436,2.744a47.331,47.331,0,0,1,6.174.172,7.955,7.955,0,0,1,2.916.515,6.024,6.024,0,0,1,2.916,2.916,7.955,7.955,0,0,1,.515,2.916c0,1.544.172,2.058.172,6.174a47.331,47.331,0,0,1-.172,6.174,7.955,7.955,0,0,1-.515,2.916,6.024,6.024,0,0,1-2.916,2.916,7.955,7.955,0,0,1-2.916.515c-1.544,0-2.058.172-6.174.172a47.331,47.331,0,0,1-6.174-.172,7.955,7.955,0,0,1-2.916-.515A6.023,6.023,0,0,1,3.43,24.526a7.955,7.955,0,0,1-.515-2.916c0-1.544-.172-2.058-.172-6.174a47.331,47.331,0,0,1,.172-6.174A7.955,7.955,0,0,1,3.43,6.346a6.16,6.16,0,0,1,1.2-1.715,2.9,2.9,0,0,1,1.715-1.2,7.955,7.955,0,0,1,2.916-.515,47.331,47.331,0,0,1,6.174-.172m0-2.744A50.678,50.678,0,0,0,9.09.172,10.589,10.589,0,0,0,5.317.858,6.714,6.714,0,0,0,2.573,2.573,6.714,6.714,0,0,0,.858,5.317,7.815,7.815,0,0,0,.172,9.09,50.678,50.678,0,0,0,0,15.436a50.678,50.678,0,0,0,.172,6.346,10.589,10.589,0,0,0,.686,3.773A6.714,6.714,0,0,0,2.573,28.3a6.714,6.714,0,0,0,2.744,1.715A10.589,10.589,0,0,0,9.09,30.7a50.677,50.677,0,0,0,6.346.172,50.677,50.677,0,0,0,6.346-.172,10.589,10.589,0,0,0,3.773-.686,7.2,7.2,0,0,0,4.459-4.459,10.589,10.589,0,0,0,.686-3.773c0-1.715.172-2.23.172-6.346A50.677,50.677,0,0,0,30.7,9.09a10.589,10.589,0,0,0-.686-3.773A6.714,6.714,0,0,0,28.3,2.573,6.714,6.714,0,0,0,25.556.858,10.589,10.589,0,0,0,21.782.172,50.677,50.677,0,0,0,15.436,0m0,7.547a7.763,7.763,0,0,0-7.89,7.89,7.89,7.89,0,1,0,7.89-7.89m0,13.035a5.054,5.054,0,0,1-5.145-5.145,5.054,5.054,0,0,1,5.145-5.145,5.054,5.054,0,0,1,5.145,5.145,5.054,5.054,0,0,1-5.145,5.145M23.669,5.317A1.887,1.887,0,1,0,25.556,7.2a1.9,1.9,0,0,0-1.887-1.887"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Instagram;
